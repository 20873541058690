<template>
  <div>
    <div
      class="block-icon-table-facture"
      @click="handleClickEye"
      title="Afficher Facture"
    >
      <font-awesome-icon icon="eye" />
    </div>
    <v-dialog
      v-model="dialog"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Afficher Facture</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>
        <v-card-text class="body-card center-text  mt-2">
          <iframe
            height="1100"
            width="760"
            :src="computedSrc + '#toolbar=0'"
          ></iframe>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: { getDataPDF: { required: true }, data: { required: true } },
  data() {
    return {
      src: null,
      loading: false,
      error: null,
      dialog: false
    };
  },
  methods: {
    async handleClickEye() {
      this.error = null;
      this.dialog = true;
      this.loading = true;
      const response = await this.getDataPDF(this.data);
      if (response.succes) {
        this.src = response.src;
      } else {
        this.error = response.error;
      }
      this.loading = false;
    },
    closeModal() {
      this.dialog = false;
    }
  },
  computed: {
    computedSrc() {
      return this.src;
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.iframe-position {
  display: block;
  margin: auto;
  width: 750px;
  height: 92%;
  margin-top: 5px;
}
</style>
<style lang="scss">
.mdalTaille {
  .modal-dialog {
    height: 98%;
  }
  .modal-content {
    height: 100%;
  }
}
</style>
